<template>
  <div class="home-container">
    <!-- 长黑条 -->
    <div class="top">
      <div class="top-main">
        <div class="top-main-left">
          <span style="cursor: pointer" @click="about"> 关于施泰根 </span>
          |
          <span style="cursor: pointer" @click="toTop"> 首页 </span>
        </div>
      </div>
    </div>

    <div class="nav" :class="locationStatus" ref="nav">
      <div class="nav-main">
        <div class="nav-main-left" v-if="location">
          <img
            class="nav-main-left-logo"
            @click="toTop"
            src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/logoword8.png"
          />
          <img class="nav-main-left-ipv6" src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/logo1white.png" />
        </div>
        <div class="nav-main-left" v-else>
          <img
            class="nav-main-left-logo"
            @click="toTop"
            src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/logoword4.png"
          />
          <img class="nav-main-left-ipv6" src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/logo1.png" />
        </div>
        <div class="nav-main-right">
          <ul>
            <!-- <li @click="tobottom">联系我们</li> -->
            <li @click="tostore">微店</li>
            <!-- <li>登录</li> -->
          </ul>
        </div>
      </div>
    </div>

    <div class="main" ref="main">
      <div class="name">施泰根（武汉）科技有限公司</div>
      <img class="main-img" src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/portalBanner6.png" />
    </div>
    <!-- 
    <div class="mottos">
      <div class="motto">
        {{str2}}
      </div>
    </div> -->

    <div class="section1">
      <div class="section1-main">
        <div class="section1-main-left">
          <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/item1-c9902511e5.png" />
        </div>
        <div class="section1-main-right">
          <h2>售后产品购买</h2>
          <div class="middle-line"></div>
          <p>
            无需排队，7*24小时随时随地缴费，高效稳定，极速到账，让人人享有便捷生活
          </p>
          <div class="jf">
            <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/5-fdbe38852d.png" />
            <div class="word">
              <h3>购买</h3>
              <span>便捷缴费 安全省心</span>
            </div>
          </div>
          <div class="zd">
            <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/10-42998855fd.png" />
            <div class="word">
              <h3>账单</h3>
              <span>实时记录 随时查询</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 
    <div class="section1" style="background-color: #f8f6f6">
      <div class="section1-main">
        <div class="section1-main-left">
          <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/item1-c9902511e5.png">
        </div>
        <div class="section1-main-right">
          <h2>电子配件</h2>
          <div class="middle-line"></div>
          <p>无需排队，7*24小时随时随地缴费，高效稳定，极速到账，让人人享有便捷生活</p>
          <div class="jf">
            <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/5-fdbe38852d.png">
            <div class="word">
              <h3>缴费</h3>
              <span>便捷缴费 安全省心</span>
            </div>
          </div>
          <div class="zd">
            <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/10-42998855fd.png">
            <div class="word">
              <h3>账单</h3>
              <span>实时记录 随时查询</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section1">
      <div class="section1-main">
        <div class="section1-main-left">
          <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/item1-c9902511e5.png">
        </div>
        <div class="section1-main-right">
          <h2>办公用品</h2>
          <div class="middle-line"></div>
          <p>无需排队，7*24小时随时随地缴费，高效稳定，极速到账，让人人享有便捷生活</p>
          <div class="jf">
            <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/5-fdbe38852d.png">
            <div class="word">
              <h3>缴费</h3>
              <span>便捷缴费 安全省心</span>
            </div>
          </div>
          <div class="zd">
            <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/10-42998855fd.png">
            <div class="word">
              <h3>账单</h3>
              <span>实时记录 随时查询</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section1" style="background-color: #f8f6f6">
      <div class="section1-main">
        <div class="section1-main-left">
          <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/item1-c9902511e5.png">
        </div>
        <div class="section1-main-right">
          <h2>日用百货</h2>
          <div class="middle-line"></div>
          <p>无需排队，7*24小时随时随地缴费，高效稳定，极速到账，让人人享有便捷生活</p>
          <div class="jf">
            <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/5-fdbe38852d.png">
            <div class="word">
              <h3>缴费</h3>
              <span>便捷缴费 安全省心</span>
            </div>
          </div>
          <div class="zd">
            <img src="https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/10-42998855fd.png">
            <div class="word">
              <h3>账单</h3>
              <span>实时记录 随时查询</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="hzsh">
      <div class="hzsh-main">
        <div class="hzsh-top">合作商户</div>
        <div class="hzsh-footer">
          <el-carousel>
            <el-carousel-item v-for="item in hzshurls" :key="item">
              <div class="imgmoban">
                <el-image class="img" :src="item" fit="contain"></el-image>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div> -->

    <div class="footer">
      <div class="contact">
        <!-- <div>联系我们：13072730658 颜先生</div> -->

  
        <div style="margin-top: 3px;">
          <a href="https://beian.miit.gov.cn/" target="_blank">
            © 2009-2022 Aliyun.com 版权所有 鄂ICP备2021020330号-1</a
          >
        </div>
      </div>

      <!-- <div class="address"> -->
      <!-- 武汉市东湖高新技术开发区关东科技工业园华光大道18号(0231) -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "main1",
  data() {
    return {
      locationStatus: "locationStatus1",
      location: true,
      hzshurls: [
        "https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/logo1.png",
        "https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/logoword4.png",
      ],
      str: "施泰根(武汉)科技有限公司于2016年05月31日成立。法定代表人颜鹏程。",
      i: 0,
      timer: 0,
      str2: "",
    };
  },
  mounted() {
    this.typing();
  },
  computed: {},
  methods: {
    tostore() {
      window.open("https://k.youshop10.com/0cINTcZM", "target");
    },
    typing() {
      if (this.i <= this.str.length) {
        this.str2 = this.str.slice(0, this.i++) + "_";
        this.timer = setTimeout(() => {
          this.typing();
        }, 100);
      } else {
        clearTimeout(this.timer);
        this.str2 = this.str;
      }
    },
    about() {
      window.open("https://aiqicha.baidu.com/company_detail_19964256324962");
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },

    tobottom() {
      //网页被卷去的高
      let top = document.body.scrollTop;

      // 实现滚动效果
      const timeTop = setInterval(() => {
        console.log("top");
        console.log(top);

        top = top + 50;
        document.body.scrollTop = top;
        document.documentElement.scrollTop = top;
        if (top >= document.body.scrollHeight) {
          clearInterval(timeTop);
        }
      }, 10);
    },

    changeCss() {
      console.log(document.documentElement.scrollTop);
      console.log(this.$refs.main.clientHeight);
      if (document.documentElement.scrollTop == 0) {
        this.locationStatus = "locationStatus1";
        this.location = true;
      } else if (
        document.documentElement.scrollTop > 0 &&
        document.documentElement.scrollTop <
          this.$refs.main.clientHeight - this.$refs.nav.clientHeight
      ) {
        this.locationStatus = "locationStatus2";
        this.location = true;
      } else {
        this.locationStatus = "locationStatus3";
        this.location = false;
      }
    },
  },
  created() {
    window.onscroll = this.changeCss;
  },
};
</script>

<style scoped lang="scss">
.home-container {
  position: relative;
  width: 100%;
  letter-spacing: 0.2px;

  .top {
    height: 35px;
    width: 100%;
    background-color: #020202;
    color: #fff;
    position: fixed;
    top: 0;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.5);
    z-index: 100;

    .top-main {
      width: 70%;
      height: 35px;
      margin: auto;
      line-height: 35px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }
  }

  .nav {
    height: 78px;
    width: 100%;
    color: #ffffff;
    position: fixed;
    top: 32px;

    .nav-main {
      width: 70%;
      // background-color: #ff00ff;
      height: 78px;
      margin: auto;
      line-height: 78px;
      display: flex;
      justify-content: space-between;
      font-size: 16px;

      .nav-main-left {
        font-size: 0;

        .nav-main-left-logo {
          width: 60px;
          line-height: 78px;
          vertical-align: middle;
        }

        .nav-main-left-ipv6 {
          margin-left: 5px;
          width: 35px;

          // line-height: 7px;
          vertical-align: middle;
          margin-bottom: 10px;
        }
      }

      .nav-main-right {
        ul {
          height: 78px;
          list-style: none;
          margin: 0;

          li {
            float: left;
            line-height: 78px;
            margin: 0 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .nav:hover {
    background-color: rgba(2, 2, 2, 0.3);
    transition: 0.4s;
  }

  .main {
    margin-top: -47px;
    // background-color: #ff0;

    .name {
      font-size: 60px;
      color: white;
      // background-color: red;
      position: relative;
      top: 300px;
      text-align: center;
      z-index: 1000;
    }
  }

  .main-img {
    width: 100%;
  }

  .section1 {
    height: 460px;
    width: 100%;

    .section1-main {
      width: 1200px;
      height: 100%;
      padding: 52px 0 0 65px;
      margin: auto;
      box-sizing: border-box;

      .section1-main-left {
        float: left;

        img {
          width: 299px;
        }
      }

      .section1-main-right {
        float: left;
        margin-left: 100px;

        h2 {
          font-size: 32px;
          font-weight: 500;
          color: #404040;
          margin: 25px 0;
        }

        .middle-line {
          width: 35px;
          border-top: 4px solid black;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: #8c8c8c;
          margin: 25px 0;
        }

        .jf {
          height: 45px;
          width: 720px;
          margin: 30px 0;

          img {
            width: 40px;
            height: 40px;
            float: left;
          }

          .word {
            float: left;
            margin-left: 25px;

            h3 {
              margin-top: 0;
              color: #666;
              margin-bottom: 0px;
            }

            span {
              font-size: 14px;
              color: #8c8c8c;
            }
          }
        }

        .zd {
          height: 45px;
          width: 720px;
          margin: 30px 0;

          img {
            width: 40px;
            height: 40px;
            float: left;
          }

          .word {
            float: left;
            margin-left: 25px;

            h3 {
              margin-top: 0;
              color: #666;
              margin-bottom: 0px;
            }

            span {
              font-size: 14px;
              color: #8c8c8c;
            }
          }
        }
      }
    }
  }

  .hzsh {
    height: 558px;
    width: 100%;

    .hzsh-main {
      width: 1200px;
      height: 100%;
      margin: auto;
      display: flow-root;

      .hzsh-top {
        width: 311px;
        height: 120px;
        background: url("https://xzota-1302399879.cos.ap-guangzhou.myqcloud.com/stg/assets/15-a8cd733877.png");
        color: #ffffff;
        margin: -35px auto 0;
        line-height: 100px;
        text-align: center;
        font-size: 32px;
      }

      .hzsh-footer {
        width: 1200px;
        height: 300px;
        background-color: gray;
        margin-top: 50px;
      }
    }
  }

  .footer {
    height: 60px;
    width: 100%;
    background-color: #343434;
  }
}

.imgmoban {
  background: -webkit-linear-gradient(
      right,
      rgba(74, 107, 196, 0.795),
      rgb(255, 255, 255),
      rgb(109, 13, 219)
    )
    no-repeat;
  display: flex;
  justify-content: center;
}

.img {
  max-width: 600px;
  max-height: 300px;
  box-shadow: 1px 10px 70px 30px rgb(249, 249, 249) inset;
}

.locationStatus1 {
  background-color: rgba(255, 255, 255, 0);
  transition: 0.4s;
}
a{
  text-decoration:none;
}
a:link{
	color: #fff;
}
a:visited{
	color: #fff;
}
a:hover{
	color: #fff;
}
a:active{
	color: #fff;
}

.locationStatus2 {
  background-color: rgba(2, 2, 2, 0.3);
  transition: 0.4s;
}

.locationStatus3 {
  background-color: rgba(255, 255, 255) !important;
  box-shadow: 1px 1px 10px 1px rgba(2, 2, 2, 0.5) !important;
  color: black !important;
  transition: 0.2s;
}

.contact {
    width: 100%;
    color: white;
    padding-top: 20px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.address {
  color: white;
  margin-top: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.mottos {
  display: flex;
  justify-content: center;
}

.motto {
  display: flex;
  text-align: center;
  font-size: 12px;
}
</style>